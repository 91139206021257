import React, { useEffect, useState } from 'react'
import {
  BehaviourAssessmentItem,
  CriteriaAssessment,
  DeliverableJustificationValue,
  DeliverableOptions,
  deliverableOptionToFinalGrade,
  FinalGrade,
  finalGradeToScore,
  getDeliverableOptionColors,
  getDeliverableOptionScore,
  getPerformanceRatingScore,
  PerformanceRating,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewDataInterface,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
  scoreToFinalGrade,
  SectionType,
  SkillCardInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import {
  CardInterface,
  getData,
  prefillCard,
  PREVIOUS_QUARTER_ID,
  updateSingleCard,
} from '@components/ScorecardGeneral/PrefillScorecardButton'
import { getUpdatedRatingCard, getVisibleSections } from '@src/utils/performance'
import { get, set } from 'lodash'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { FormError } from '@src/features/Form/LapeForm'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { notReachable } from '@src/utils/notReachable'
import produce from 'immer'
import { GradesMapInterface } from '@src/utils/grades'
import { Text, VStack } from '@revolut/ui-kit'
import round from 'lodash/round'
import { perfGradesCalculationConfig } from '@src/interfaces/performanceGrades'
import { CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'

export const ratingOptions = [
  { key: DeliverableOptions.DONT_KNOW, text: "Don't know" },
  { key: DeliverableOptions.POOR, text: 'Poor' },
  { key: DeliverableOptions.BASIC, text: 'Basic' },
  { key: DeliverableOptions.INTERMEDIATE, text: 'Intermediate' },
  { key: DeliverableOptions.ADVANCED, text: 'Advanced' },
  { key: DeliverableOptions.EXPERT, text: 'Expert' },
]

export const cultureOptions = [
  { key: AssessBehaviourButtonTypes.unknown, text: "Don't know" },
  { key: AssessBehaviourButtonTypes.negative, text: 'Improvement Focus' },
  { key: AssessBehaviourButtonTypes.neutral, text: 'Performing' },
  { key: AssessBehaviourButtonTypes.positive, text: 'Superpower' },
]

export const cultureOptionsNewGrades = [
  { key: AssessBehaviourButtonTypes.unknown, text: "Don't know" },
  { key: AssessBehaviourButtonTypes.negative, text: 'Developing' },
  { key: AssessBehaviourButtonTypes.neutral, text: 'Performing' },
  { key: AssessBehaviourButtonTypes.positive, text: 'Exceeding' },
]

export const kpiRating = [
  {
    key: 'poor' as DeliverableOptions,
    text: 'Poor',
    description: [
      'Fails to deliver most tasks assigned, often shows poor quality in execution.',
      'Makes almost no impact towards the team achieving its goals.',
    ],
  },
  {
    key: 'basic' as DeliverableOptions,
    text: 'Basic',
    description: [
      'Works hard to deliver mostly simple tasks on time and with good quality.',
      'Makes a positive contribution towards the team achieving its goals through medium impact deliverables.',
    ],
  },
  {
    key: 'intermediate' as DeliverableOptions,
    text: 'Intermediate',
    description: [
      'Delivers a mixture of simple and complex tasks on time and with great quality.',
      'Makes an essential contribution towards the team achieving its goals and has an impact on the department goals too.',
    ],
  },
  {
    key: 'advanced' as DeliverableOptions,
    text: 'Advanced',
    description: [
      'Delivers highly complex tasks often before the deadline and always with exceptional quality.',
      'Contributes an essential impact on the department achieving its goals and in turn the associated company goals.',
    ],
  },
  {
    key: 'expert' as DeliverableOptions,
    text: 'Expert',
    description: [
      'Contributes greatly towards the department exceeding its goals and makes essential impacts towards the company exceeding its goals.',
    ],
  },
]

export const deliverablesRating = [
  {
    key: 'dont_know' as DeliverableOptions,
    text: "Don't know",
    description: ["I don't have enough data to assess the deliverables"],
  },
  ...kpiRating,
]

export enum CardContentTypes {
  KPI = 'KPI',
  DELIVERABLES = 'DELIVERABLES',
  SKILLS = 'SKILLS',
  MANAGER_SKILLS = 'MANAGER_SKILLS',
  VALUES = 'VALUES',
}

export interface SelectedFieldInterface {
  type: CardContentTypes
  field: CardField
  cardIndex?: number
}

export interface GradeOption {
  key: DeliverableOptions | AssessBehaviourButtonTypes
  text: string
  aboveExp: boolean | null
  belowExp: boolean | null
  description?: string[]
  tooltip?: React.ReactNode
}

export interface CommonCardProps {
  onHelpClick?: (helpTab: HelpTabs) => void
  gradesMap: GradesMapInterface
}

export interface CommonSectionProps {
  reviewData: ReviewDataInterface | ReviewSummaryDataInterface | null
  isViewMode?: boolean
}

export interface DeliverablesGradesOptionInterface
  extends Omit<GradeOption, 'key' | 'description'> {
  key: DeliverableOptions
  description: string[]
}

export const getRoundedRating = (
  rating?: PerformanceRating,
): DeliverableOptions | null => {
  if (!rating) {
    return null
  }
  switch (rating) {
    case PerformanceRating.skipped:
    case PerformanceRating.dont_know:
      return DeliverableOptions.DONT_KNOW
    case PerformanceRating.poor_plus:
    case PerformanceRating.poor:
      return DeliverableOptions.POOR
    case PerformanceRating.improvement_needed:
    case PerformanceRating.basic_plus:
    case PerformanceRating.basic:
    case PerformanceRating.basic_minus:
      return DeliverableOptions.BASIC
    case PerformanceRating.performing:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_minus:
      return DeliverableOptions.INTERMEDIATE
    case PerformanceRating.superpower:
    case PerformanceRating.advanced_plus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_minus:
      return DeliverableOptions.ADVANCED
    case PerformanceRating.expert_plus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_minus:
      return DeliverableOptions.EXPERT
    default:
      return notReachable(rating)
  }
}

export const PerfRatingToGraphNumber = {
  [PerformanceRating.skipped]: 0,
  [PerformanceRating.dont_know]: 0,
  [PerformanceRating.poor]: 1,
  [PerformanceRating.poor_plus]: 1,
  [PerformanceRating.basic_minus]: 2,
  [PerformanceRating.basic]: 2,
  [PerformanceRating.basic_plus]: 2,
  [PerformanceRating.intermediate_minus]: 3,
  [PerformanceRating.intermediate]: 3,
  [PerformanceRating.intermediate_plus]: 3,
  [PerformanceRating.advanced_minus]: 4,
  [PerformanceRating.advanced]: 4,
  [PerformanceRating.advanced_plus]: 4,
  [PerformanceRating.expert_minus]: 5,
  [PerformanceRating.expert]: 5,
  [PerformanceRating.expert_plus]: 5,
  [PerformanceRating.improvement_needed]: 0,
  [PerformanceRating.performing]: 1,
  [PerformanceRating.superpower]: 2,
}

const getUpdatedCardRating = async (
  values: ReviewScorecardInterface,
  card: SkillCardInterface,
) => {
  try {
    const resp = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      card,
    )

    card.rating = resp?.rating
    return card
  } catch (e) {
    return undefined
  }
}

export const onPrefillWithGrade = async (
  values: ReviewScorecardInterface,
  grade: GradeOption,
  type: PerformanceReviewTypes,
  cardIndex?: number,
) => {
  if (cardIndex === undefined) {
    return
  }

  const level = grade.key
  let updatedValues: CardInterface | undefined = getData(values, type, cardIndex)

  if (!updatedValues) {
    return
  }

  updateSingleCard(updatedValues, level)

  // to delay a bit to make switching between options smoother
  setTimeout(async () => {
    if (!updatedValues || 'internal_data_id' in updatedValues) {
      return
    }

    updatedValues = await getUpdatedCardRating(values, updatedValues)
  }, 300)
}

export const onPrefillCultureValue = async (
  values: ReviewScorecardInterface,
  grade: GradeOption,
  field: string,
) => {
  let card = get(values, field)

  if (!card) {
    return
  }

  card?.sections?.forEach((section: BehaviourAssessmentItem) => {
    section.value = grade.key as AssessBehaviourButtonTypes
  })
}

export const countSuperpowers = (cards: ValueBasedCardInterface[]) => {
  return cards.reduce((count, card) => {
    count += card.sections.filter(section => section.value === 'superpower').length
    return count
  }, 0)
}

export const RATING_SCORE_MAP = {
  [AssessBehaviourButtonTypes.positive]: 4,
  [AssessBehaviourButtonTypes.neutral]: 3,
  [AssessBehaviourButtonTypes.negative]: 2,
  [AssessBehaviourButtonTypes.unknown]: 0,
}

// Each rating (improvement/performing/superpower) is given a score; from 2 to 4.
// When calculating the section average, ignore "Don't Know" answers.
// To get the overall section ratings:
// 1 - round the average to the nearest integer. If the score is .5, we should round down.
// 2 - if number of "Don't Know" answers is greater than the others,
// section rating should be "Don't Know"
export const countValueRating = (card: ValueBasedCardInterface) => {
  const scoreSum = card.sections.reduce(
    (acc, val) => acc + (val.value ? RATING_SCORE_MAP[val.value] : 0),
    0,
  )
  // if .5 round down
  const score = -Math.round(-(scoreSum / card.sections.length))

  if (score >= 4) {
    return AssessBehaviourButtonTypes.positive
  }
  if (score >= 3) {
    return AssessBehaviourButtonTypes.neutral
  }
  if (score >= 2) {
    return AssessBehaviourButtonTypes.negative
  }
  return AssessBehaviourButtonTypes.unknown
}

export const updateValueRating = ({
  values,
  path,
}: {
  values: ReviewScorecardInterface
  path: string
}) => {
  const currentCard = get(values, path)
  const rating = countValueRating(currentCard)

  if (rating) {
    set(values, `${path}.rating`, rating)
  }
}

export const cardHasMissingValues = (
  type: CardContentTypes,
  card?: SkillCardInterface | SummarySkillCardInterface,
  errors?: FormError<ReviewScorecardInterface>,
) => {
  if (type === CardContentTypes.DELIVERABLES) {
    const hasJustificationError = errors?.review_data?.deliverables?.justifications?.some(
      item => {
        return (item as DeliverableJustificationValue)?.reference_url
      },
    )
    if (hasJustificationError) {
      return true
    }
    return card?.sections?.some(section => {
      return 'value' in section && !section.value
    })
  }

  const sections = getVisibleSections(card?.sections as CriteriaAssessment[])
  return sections.some(section => {
    if ('items' in section) {
      return section.items?.some(item => !item.value)
    }
    return false
  })
}

export const getReviewerRelation = (type: RecommendationTypes) => {
  switch (type) {
    case RecommendationTypes.LM:
      return 'LM'
    case RecommendationTypes.FM:
      return 'FM'
    case RecommendationTypes.PEER:
      return 'Peer'
    default:
      return ''
  }
}

export const usePrefillSkillsWithPreviousQuarter = (
  values: ReviewScorecardInterface,
  skillsCards?: SkillCardInterface[],
) => {
  const [prefillCompleted, setPrefillCompleted] = useState(false)

  useEffect(() => {
    const firstCard = (skillsCards?.[0]?.sections?.[0] as CriteriaAssessment)?.items?.[0]

    // if first found section item has value, then all cards were already pre-filled
    if (!!skillsCards && !firstCard?.value && firstCard?.previous_values?.[0]?.value) {
      Promise.allSettled(
        skillsCards.map(async card => {
          prefillCard(card, PREVIOUS_QUARTER_ID)
          await getUpdatedCardRating(values, card)
        }),
      ).finally(() => setPrefillCompleted(true))
    } else {
      setPrefillCompleted(true)
    }
  }, [skillsCards])

  return { prefillCompleted }
}

export const shouldOpenNextReview = (category: ReviewCategory) => {
  switch (category) {
    case ReviewCategory.Performance:
    case ReviewCategory.Probation:
    case ReviewCategory.Upwards:
      return true
    case ReviewCategory.PIP:
    case ReviewCategory.PIP_V2:
      return false
    default:
      return notReachable(category)
  }
}

export const shouldScrollToError = (
  errors: FormError<ReviewScorecardInterface>,
  field: string,
) => {
  const firstError = produce(errors, draft => {
    const firstErrorEntry = draft.review_data && Object.entries(draft.review_data)[0]
    if (firstErrorEntry) {
      draft.review_data = { [firstErrorEntry[0]]: firstErrorEntry[1] }
    }
  })
  return !!get(firstError, field)
}

export const getDeliverablesGrades = (
  gradesMap: GradesMapInterface,
  cards?: SkillCardInterface[] | SummarySkillCardInterface[],
) => {
  return cards?.[0].sections?.[0]?.type === SectionType.SingleChoice
    ? cards[0].sections[0].options
        .filter(option => option.key !== DeliverableOptions.DONT_KNOW)
        .map(option => {
          const description = deliverablesRating.find(rating => rating.key === option.key)
            ?.description || [option.text]
          return {
            key: option.key,
            text: gradesMap[deliverableOptionToFinalGrade(option.key)],
            description,
            color: getDeliverableOptionColors(option.key).text,
          }
        })
    : []
}

export const getKpiGrades = (gradesMap: GradesMapInterface, hasKpis: boolean) =>
  [
    {
      key: DeliverableOptions.POOR,
      text: gradesMap[deliverableOptionToFinalGrade(DeliverableOptions.POOR)],
      description: [
        hasKpis
          ? 'Goal progress is below 40%'
          : 'Less than 40% contribution towards the team goals',
      ],
    },
    {
      key: DeliverableOptions.BASIC,
      text: gradesMap[deliverableOptionToFinalGrade(DeliverableOptions.BASIC)],
      description: [
        hasKpis
          ? 'Goal progress is between 40% and 60%'
          : 'Contribution of 40-60% towards the team goals',
      ],
    },
    {
      key: DeliverableOptions.INTERMEDIATE,
      text: gradesMap[deliverableOptionToFinalGrade(DeliverableOptions.INTERMEDIATE)],
      description: [
        hasKpis
          ? 'Goal progress is between 60% and 80%'
          : 'Contribution of 60-80% towards the team goals',
      ],
    },
    {
      key: DeliverableOptions.ADVANCED,
      text: gradesMap[deliverableOptionToFinalGrade(DeliverableOptions.ADVANCED)],
      description: [
        hasKpis
          ? 'Goal progress is between 80% and 120%'
          : 'Contribution of 80-120% towards the team goals',
      ],
    },
    {
      key: DeliverableOptions.EXPERT,
      text: gradesMap[deliverableOptionToFinalGrade(DeliverableOptions.EXPERT)],
      description: [
        hasKpis
          ? 'Goal progress is above 120%'
          : 'Greater than 120% contribution towards the team goals',
      ],
    },
  ].map(grade => ({
    ...grade,
    tooltip: (
      <VStack>
        {grade.description.map((item, ind) => (
          <Text key={ind}>{item}</Text>
        ))}
      </VStack>
    ),
  }))

const getCardDetails = (cards?: SkillCardInterface[] | ValueBasedCardInterface[]) => {
  let valuesScore = 0
  const hasMissingValues = cards?.some(card => {
    if (card.rating) {
      const score = getPerformanceRatingScore(card.rating)
      const hasExpectation = 'rating_expectation' in card
      let scoreValue = 0
      if (!hasExpectation) {
        scoreValue = score
      } else if (card.rating_expectation) {
        scoreValue = score - getDeliverableOptionScore(card.rating_expectation)
      }
      valuesScore += scoreValue
    }
    return !card.rating
  })

  return { hasMissingValues, valuesScore: round(valuesScore, 2) }
}

const calculateCultureValuesGrade = (average: number) => {
  if (average >= perfGradesCalculationConfig.values_average_limits.exceptional) {
    return FinalGrade.Exceptional
  }
  if (average >= perfGradesCalculationConfig.values_average_limits.strong) {
    return FinalGrade.Strong
  }
  if (average >= perfGradesCalculationConfig.values_average_limits.average_plus) {
    return FinalGrade.AveragePlus
  }
  if (average >= perfGradesCalculationConfig.values_average_limits.average_minus) {
    return FinalGrade.AverageMinus
  }
  return FinalGrade.Poor
}

const calculateSkillsGrade = (average: number) => {
  if (average >= perfGradesCalculationConfig.skills_average_limits.exceptional) {
    return FinalGrade.Exceptional
  }
  if (average >= perfGradesCalculationConfig.skills_average_limits.strong) {
    return FinalGrade.Strong
  }
  if (average >= perfGradesCalculationConfig.skills_average_limits.average_plus) {
    return FinalGrade.AveragePlus
  }
  if (average >= perfGradesCalculationConfig.skills_average_limits.average_minus) {
    return FinalGrade.AverageMinus
  }
  return FinalGrade.Poor
}

type RecommendedGrade = FinalGrade | null

export interface SectionsRecommendedGradesInterface {
  kpiGrade: RecommendedGrade
  deliverablesGrade: RecommendedGrade
  skillsGrade: RecommendedGrade
  cultureValuesGrade: RecommendedGrade
  cultureSkillsGrade: RecommendedGrade
}

export interface RecommendedGradesInterface {
  recommendedGrade: RecommendedGrade
  grades: SectionsRecommendedGradesInterface | null
}

const hasValuesForGradeCalculation = (values: ReviewScorecardInterface) => {
  let kpiGrade = null
  let deliverablesGrade = null
  let skillsGrade = null
  let cultureValuesGrade = null
  let cultureSkillsGrade = null

  let recommendedGrade: FinalGrade | null = null
  let grades: SectionsRecommendedGradesInterface | null = null

  // starting from the bottom of the form as last values are most likely to have missing values
  // Culture Values
  const cultureValuesCards = values.review_data.culture_values?.cards
  if (cultureValuesCards) {
    const { hasMissingValues, valuesScore } = getCardDetails(cultureValuesCards)
    if (hasMissingValues) {
      return { canCalculate: false, recommendedGrade, grades }
    }
    const cultureValuesScoreAverage = valuesScore / cultureValuesCards.length
    cultureValuesGrade = calculateCultureValuesGrade(cultureValuesScoreAverage)
  }

  // Culture Skills
  const cultureSkillsCards = values.review_data.culture_skills?.cards
  if (cultureSkillsCards) {
    const { hasMissingValues, valuesScore } = getCardDetails(cultureSkillsCards)
    if (hasMissingValues) {
      return { canCalculate: false, recommendedGrade, grades }
    }
    const cultureSkillsScoreAverage = valuesScore / cultureSkillsCards.length
    cultureSkillsGrade = calculateSkillsGrade(cultureSkillsScoreAverage)
  }

  // FunctionalSkills
  const skillsCards = values.review_data.functional_skills?.cards
  if (skillsCards) {
    const { hasMissingValues, valuesScore } = getCardDetails(skillsCards)
    if (hasMissingValues) {
      return { canCalculate: false, recommendedGrade, grades }
    }
    const skillsScoreAverage = valuesScore / skillsCards.length
    skillsGrade = calculateSkillsGrade(skillsScoreAverage)
  }

  // KPIs
  const kpisSection = values.review_data.kpis_section
  if (kpisSection) {
    if (!kpisSection.recommended_rating) {
      return { canCalculate: false, recommendedGrade, grades }
    }
    kpiGrade = deliverableOptionToFinalGrade(kpisSection.recommended_rating)
  }

  // Deliverables
  const deliverablesSection = values.review_data.deliverables
  if (deliverablesSection) {
    const firstCard = deliverablesSection.cards?.[0].sections[0]
    if (firstCard?.type === SectionType.SingleChoice) {
      if (!firstCard.value) {
        return { canCalculate: false, recommendedGrade, grades }
      }
      deliverablesGrade = deliverableOptionToFinalGrade(firstCard.value)
    }
  }

  const deliverablesCoefficient = perfGradesCalculationConfig.deliverables_coefficient
  const skillsCoefficient = perfGradesCalculationConfig.skills_coefficient

  const getFinalScore = (grade: FinalGrade | null, coefficient: number) =>
    finalGradeToScore(grade) * coefficient

  const kpiScore = getFinalScore(kpiGrade, deliverablesCoefficient)
  const deliverablesScore = getFinalScore(deliverablesGrade, deliverablesCoefficient)
  const skillsScore = getFinalScore(skillsGrade, skillsCoefficient)
  const cultureValuesScore = getFinalScore(cultureValuesGrade, skillsCoefficient)
  const cultureSkillsScore = getFinalScore(cultureSkillsGrade, skillsCoefficient)

  const totalScore =
    kpiScore + deliverablesScore + skillsScore + cultureValuesScore + cultureSkillsScore

  recommendedGrade = scoreToFinalGrade(totalScore)
  grades = {
    kpiGrade,
    deliverablesGrade,
    skillsGrade,
    cultureValuesGrade,
    cultureSkillsGrade,
  }

  return {
    canCalculate: true,
    recommendedGrade,
    grades,
  }
}

export const getRecommendedGrade = (values?: ReviewScorecardInterface) => {
  if (!values || values.category !== ReviewCategory.Performance) {
    return { recommendedGrade: null, grades: null }
  }

  // we need to calculate values selected for each section and do some calculations to
  // show recommended rating and pre-fill grade recommendation
  // TODO: REVC-5947 - move grade calculation logic (at least config) to BE
  const { canCalculate, recommendedGrade, grades } = hasValuesForGradeCalculation(values)

  if (!canCalculate) {
    return { recommendedGrade: null, grades: null }
  }

  return { recommendedGrade, grades }
}

export const getFinalGradeTitleFromRating = (
  gradesMap: GradesMapInterface,
  rating?: PerformanceRating,
) => {
  const roundedRating = getRoundedRating(rating)
  const finalGrade = roundedRating ? deliverableOptionToFinalGrade(roundedRating) : null

  return finalGrade ? gradesMap[finalGrade] : undefined
}

export const getSkillsMissingJustification = (values?: ReviewScorecardInterface) => {
  const skillsCards = values?.review_data.functional_skills?.cards
  if (!skillsCards) {
    return { skillsMissingJustification: [] }
  }

  const skillsMissingJustification: number[] = []
  skillsCards.forEach((card, ind) => {
    if (!card.rating) {
      return
    }
    const rating = getRoundedRating(card.rating)
    const ratingIsDiffer =
      rating !== card.rating_expectation && rating !== DeliverableOptions.DONT_KNOW
    const missingJustification = !card.justification
    if (ratingIsDiffer && missingJustification) {
      skillsMissingJustification.push(ind)
    }
  })

  return { skillsMissingJustification }
}
