import { Statuses } from '@src/interfaces'
import {
  NotCompletedReviewInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import React, { useState } from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { getCleanValuesBeforeSave } from '@src/utils/performance'
import { shouldOpenNextReview } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getReviewUrl } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'

interface Props {
  reviewsList?: NotCompletedReviewInterface[]
}

export const SaveDraftButton = connect(({ reviewsList }: Props) => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submit, isSubmitting } = form
  const [openNextReview, setOpenNextReview] = useState(false)
  const reviewUrl = getReviewUrl(id, employeeId, true, reviewsList)

  if (
    values.status !== Statuses.draft &&
    values.status !== Statuses.pending &&
    values.status !== Statuses.rejected
  ) {
    return null
  }

  const goNext = () => {
    setOpenNextReview(false)
    navigateTo(reviewUrl)
  }

  const onSaveDraft = () => {
    form.values = getCleanValuesBeforeSave(values)
    form.values.status = Statuses.draft
    const canOpenNextReview = shouldOpenNextReview(values.category)

    return submit().then(() => {
      if (canOpenNextReview) {
        setOpenNextReview(true)
      } else {
        goBack(
          pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
            id: employeeId,
          }),
        )
      }
    })
  }

  return (
    <>
      <StatusPopup variant="success" open={openNextReview} onClose={goNext}>
        <StatusPopup.Title>Feedback saved to drafts</StatusPopup.Title>
      </StatusPopup>

      <Button
        onClick={onSaveDraft}
        variant="secondary"
        pending={isSubmitting && values.status === Statuses.draft}
        disabled={isSubmitting}
        width={167}
        elevated
        data-testid="btn-save-draft"
      >
        Save to Drafts
      </Button>
    </>
  )
})
