import React from 'react'
import { Ratings } from '@src/interfaces/performance'
import { connect } from 'lape'
import { Box, chain, Flex, InputGroup, Text, Token, VStack } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { get, set } from 'lodash'
import {
  CommonSectionProps,
  getRoundedRating,
  GradeOption,
  kpiRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { relationToString } from '@src/features/Scorecard/constants'
import BottomText from '@components/Inputs/partials/BottomText'

interface KpiProps extends CommonSectionProps {
  kpiGrades: GradeOption[]
  justificationOnly?: boolean
  showJustificationError?: boolean
}

export const KPI = connect(
  ({
    reviewData,
    isViewMode = false,
    kpiGrades,
    justificationOnly = false,
    showJustificationError,
  }: KpiProps) => {
    if (!reviewData) {
      return null
    }
    const getFieldDetails = (option: GradeOption) => {
      const ratingValue = get(reviewData, 'kpis_section.recommended_rating')
      const isChecked =
        kpiRating.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }

    const getViewFieldDetails = (option: GradeOption) => {
      const ratings =
        get(reviewData, 'kpis_section.recommended_ratings')?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    return (
      <VStack width="100%" space="s-8">
        {!justificationOnly && (
          <Flex
            backgroundColor={Token.color.greyTone2}
            borderRadius={Token.radius.r16}
            style={{ overflow: 'hidden' }}
          >
            <InputGroup style={{ width: '100%' }}>
              {kpiGrades.map((option, optionIdx) => {
                const { isChecked, ratings } = isViewMode
                  ? getViewFieldDetails(option)
                  : getFieldDetails(option)
                return (
                  <DeliverablesGrade
                    key={optionIdx}
                    isViewMode={isViewMode}
                    isChecked={isChecked}
                    ratings={ratings}
                    option={option}
                    onChange={() => {
                      set(reviewData, 'kpis_section.recommended_rating', option.key)
                    }}
                  />
                )
              })}
            </InputGroup>
          </Flex>
        )}
        {isViewMode ? (
          <VStack space="s-8">
            {reviewData?.kpis_section?.comments?.map(comment => (
              <Box
                p="s-16"
                key={comment.review.id}
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <VStack space="s-8">
                  <Text variant="primary">
                    {chain(
                      comment.review.reviewer.full_name,
                      relationToString(comment.review.reviewer_relation, true),
                    )}
                  </Text>
                  <Text variant="secondary">{comment.value}</Text>
                </VStack>
              </Box>
            ))}
          </VStack>
        ) : (
          <>
            <LapeNewTextArea
              rows={2}
              label="Please add your justification for this grade"
              name="review_data.kpis_section.comment"
              data-testid="comment"
              hideOptional
              hasError={showJustificationError}
            />
            {showJustificationError && <BottomText error="Please add justification" />}
          </>
        )}
      </VStack>
    )
  },
)
